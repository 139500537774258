import { getEnv } from "../env";
import { getCurrentLanguage, getLangCode } from "../lang";

async function loadScript(url) {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;

        // Most browsers
        script.onload = resolve;

        // IE 6 & 7
        script.onreadystatechange = function() {
            if (this.readyState == 'complete') {
                resolve();
            }
        };

        // Error handling
        script.onerror = function() {
            reject();
        };

        document.getElementsByTagName('head')[0].appendChild(script);
    });
}

export async function loadCloudpayments() {
    if (!window.cp) {
        await loadScript('https://widget.cloudpayments.ru/bundles/cloudpayments.js')
    }
}


function makeReceipt(amount, { email = "", item_label = null, phone = "" } = {}) {
    var receipt = {
        "Items": [//товарные позиции
            {
                "label": item_label || "Расширенное прочтение", //наименование товара
                "price": amount, //цена
                "quantity": 1.00, //количество
                "amount": amount, //сумма
                "vat": 0, //ставка НДС
                "method": 4, // тег-1214 признак способа расчета - признак способа расчета
                "object": 10, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
                "measurementUnit": "шт" //единица измерения
            }
        ],
        // "calculationPlace": "www.my.ru", //место осуществления расчёта, по умолчанию берется значение из кассы
        // "taxationSystem": 0, //система налогообложения; необязательный, если у вас одна система налогообложения
        "email": email || null, //e-mail покупателя, если нужно отправить письмо с чеком
        "phone": phone || null, //телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
        "isBso": false, //чек является бланком строгой отчётности
        "amounts":
        {
            "electronic": amount, // Сумма оплаты электронными деньгами
            "advancePayment": 0.00, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
            "credit": 0.00, // Сумма постоплатой(в кредит) (2 знака после запятой)
            "provision": 0.00 // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
        }
    }


    // var data = { //содержимое элемента data
        // "cloudPayments": {
            // "customerReceipt": receipt, //онлайн-чек
        // }
    // }
    return receipt;
}


export async function CloudpaymentsCharge({ payment_amount, payment_id, payment_account_id, payment_email }, description, receipt_description = null) {
    await loadCloudpayments()
    var widget = new cp.CloudPayments({language: getLangCode()});
    return new Promise((resolve, reject) => {
        widget.charge({ // options
            publicId: getEnv('CLOUDPAYMENTS_PUBLIC_KEY'),
            description: description,
            amount: parseFloat(payment_amount), //сумма
            currency: getCurrentLanguage() === 'en' ? 'USD' : 'RUB',
            invoiceId: payment_id, //номер заказа
            accountId: payment_account_id, //плательщик
            skin: getEnv('CLOUDPAYMENTS_WIDGET_SKIN') || 'modern',
            data: {
                "cloudPayments": {
                    "customerReceipt": makeReceipt( //онлайн-чек
                        payment_amount,
                        {
                            email: payment_email,
                            phone: "",
                            item_label: receipt_description,
                        }
                    ),
                }
            }
        },
        function (options) { // success
            resolve({ options });
        },
        function (reason, options) { // fail
            reject({ reason, options })
        });
    })
}
