const LISTENERS = new Map();

let CURRENT_THEME = null;

export function initCurrentTheme() {
    setCurrentTheme(localStorage.getItem('CURRENT_THEME') ?? 'night')
}

export function setCurrentTheme(v) {
    if (CURRENT_THEME === v) {
        return;
    }

    CURRENT_THEME = v;

    localStorage.setItem('CURRENT_THEME', CURRENT_THEME)

    for (const listener of LISTENERS.values()) {
        listener(CURRENT_THEME)
    }
}

/**
 * Add listener for setCurrentTheme event
 * Returns unsubscribe function
 */
export function addCurrentThemeListener(listener) {
    const key = Symbol()
    LISTENERS.set(key, listener);
    return function() {
        LISTENERS.delete(key)
    }
}


export function getCurrentTheme() {
    return CURRENT_THEME;
}

export function switchCurrentTheme() {
    setCurrentTheme(getCurrentTheme() === 'day' ? 'night' : 'day');
}

