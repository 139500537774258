import page from "page";

import { modalController } from "../controllers/modal";
import { getNavSidebar } from "../controllers/navigation/navigation";
import { isAuthorized, setIsAuthorized } from "./auth";
import { getEnv } from "./env";
import { getCurrentLanguage, getLangCode, lang } from "./lang";
import { CloudpaymentsCharge } from "./payments/cloudpayments";

var LOADED_ICONS = {
};

const BASE_URL = import.meta.env['BASE_URL'];

let ACCESS_TOKEN;

export const orderComplete = function(paymentIntentId, btn = null) {
    setLoading(false, btn);
    document.querySelector(".result-message").classList.remove("hidden");
    if(btn) {
        if (!btn.disabled) {
            btn.disabled = true;
        }
    }
};

export const showError = function(errorMsgText, btn = null) {
    setLoading(false, btn);
    var errorMsg = document.querySelector("#card-error");
    errorMsg.textContent = errorMsgText;
    setTimeout(function() {
        errorMsg.textContent = "";
    }, 4000);
};

export const setLoading = function(isLoading, btn = null) {
    if (!btn) {
        document.querySelectorAll(".btn-with-spinner").forEach(el => setLoading(isLoading, el));
        return;
    }

    btn.disabled = isLoading;

    btn.getElementsByClassName('btn-with-spinner__spinner')[0].classList[isLoading ? 'remove' : 'add']('hidden')
    btn.getElementsByClassName('btn-with-spinner__text')[0].classList[isLoading ? 'add' : 'remove']('hidden')
};

function subscriptionPopup() {
    // xhrGet(BASE_URL + '/account/membership', function (m) {
    //     if (m.membership.plan.code === 'free' && !sessionStorage['subs_popup_shown']) {
    //         setTimeout(function() {
    //             sessionStorage.setItem('subs_popup_shown', true);
    //             showBanner({ code: 'membership-update', redirect_url: '/subscriptions' })
    //         }, 700);
    //     }
    // });
}

export function teamsPopup() {
    // if(LANG==='ru') {
    //     if (!localStorage['hdx_banner_teams']) {
    //         setTimeout(function () {
    //             localStorage.setItem('hdx_banner_teams', true);
    //             showBanner({code: 'team-update', redirect_url: '/account/team/new'})
    //         }, 500);
    //     }
    // }
}

function loadBanner(banner, cb) {
    xhrGet(BASE_URL + '/pages/%2Fbanners%2F' + banner.code, function (r) {
        if (r.found === true) {
            cb(r);
        }
    })
}
export function showBanner (banner, onSubmit) {
    loadBanner(banner, async function (r) {
        modal = modalController({inner_cls: 'no_padding'}),
        form = {
            $el: (await modalTemplates('subscriptions'))({
                data: r.page.content,
                onSubmit: function () {
                    modal.remove();
                    if (onSubmit) {
                        onSubmit();
                    }
                    if (banner.redirect_url) {
                        page(banner.redirect_url);
                    }
                }
            })
        };
        modal.container.appendChild(form.$el);
    });
}

export function ymGoal(goal) {
    if (getEnv('IS_YM_ENABLED')) {
        const ymID = window.hydrateVars?.METRICS?.ymCounter;
        if (ymID) {
            ym(ymID, 'reachGoal', goal);
        }

        if (gtag) {
            gtag('event', goal);
        }
    } else if (getEnv('DEBUG_MODE')) {
        console.debug('GOAL: ', goal);
    }
}

export function fbq() {
    if (getEnv('IS_FBQ_ENABLED')) {
        window.fb(...arguments)
    } else if (getEnv('DEBUG_MODE')) {
        console.debug('GOAL: ', goal);
    }
}

export function getNoun(number, one, two, five) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
}

export function getElementOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      top: rect.top + window.pageYOffset,
      left: rect.left + window.pageXOffset
    };
}

export function getCurAngle (node) {
    var st = window.getComputedStyle(node, null),
        tr = st.getPropertyValue("-webkit-transform") ||
            st.getPropertyValue("-moz-transform") ||
            st.getPropertyValue("-ms-transform") ||
            st.getPropertyValue("-o-transform") ||
            st.getPropertyValue("transform") ||
            "FAIL";
        if (tr === 'FAIL') {
            return false;
        }
        var values = tr.split('(');
        if (values.length > 0) {
            values = values[1].split(')')
        } else {
            return false;
        }
        if (values.length > 0) {
            values = values[0].split(',')
        } else {
            return false;
        }
        var a = values[0],
            b = values[1],
            c = values[2],
            d = values[3],
            scale = Math.sqrt(a*a + b*b),
            sin = b/scale;

    // next line works for 30deg but not 130deg (returns 50);
    // var angle = Math.round(Math.asin(sin) * (180/Math.PI));
    return Math.round(Math.atan2(b, a) * (180/Math.PI));
}


export function toDegrees (rad) {
    return rad * (180 / Math.PI);
}

export function checkChartParams(p) {
    if (!p.year || !p.month || !p.placeId || !p.day || !p.hours || !p.minutes) {
        return false;
    }
    return true;
}

export function filterArr(search, arr, key, start = false, cmp = null) {
    var filtered = [];
    var str = search;
    if (start) {
        str = '^'+str;
    }
    var re = new RegExp(str.toString().toLowerCase(), 'g');
    for (var i=0; i<arr.length; i++) {
        if (arr[i][key].toString().toLowerCase().match(re) || cmp != null && cmp(arr[i], re)) {
            filtered.push(arr[i]);
        }
    }
    return filtered;

}

function isFunction(functionToCheck) {
 return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function addSocialBlock(cont, text, url, cb = null) {
    var icons = {},
        socials = (getCurrentLanguage()==='ru') ? ['vk','ok','lj'] : ['fb','vk','ok','tw','lj'],
        holder, wrapper,
        shareText = encodeURIComponent(lang('Я - ') + text + '! ' + lang('Узнай, кто ты!'));

    var shareUrl = encodeURIComponent(window.location.href);
    if (isFunction(url) && cb === null) {
        cb = url;
    } else {
        shareUrl = encodeURIComponent(url);
    }
    var links = {
            'fb': 'https://www.facebook.com/sharer/sharer.php?u=' + shareUrl,
            'vk': 'http://vk.com/share.php?url=' + shareUrl + '&title=' + shareText,
            'ok': 'https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=' + shareUrl,
            'tw': 'https://twitter.com/intent/tweet?url=' + shareUrl + '&text=' + shareText,
            'lj': 'http://www.livejournal.com/update.bml?subject=' + shareText+ '&event=' + shareUrl
        };

    preloadIcons(socials, icons, function () {
        holder = createItem('div', 'social_holder default_social');
        wrapper = createItem('div', 'social_wrapper');

        holder.appendChild(wrapper);

        socials.forEach(function (el, i) {
            var a = createItem('a', 'soc_link sl_' + el);

            a.setAttribute('href', links[el]);
            a.setAttribute('target', '_blank');
            a.innerHTML = icons[el];
            wrapper.appendChild(a);

            /*
            a.addEventListener('click', function (e) {
                e.preventDefault();
                console.log(132)
                window.open(links[el]);
            });
*/
            if (i === socials.length-1) {
                cb(holder);
            }
        });
    });
}

function addLoadedIcon(k, v, o, c) {
    o[k] = v;
    c(o);
}

function preloadIcons(arr, loadedObj, cb) {
    var preloadIcon = function (e) {
        xhrGetIcon(getEnv('STATIC_BASE_URL') + '/svg/'+e.toLocaleLowerCase()+'.svg', function (icon) {
            addLoadedIcon(e, icon, loadedObj, function (loaded) {
                if(Object.keys(loaded).length === arr.length){
                    cb();
                }
            });
        });
    };
    arr.forEach(preloadIcon);
}

export function createItem(tag, cls = null, patch = (item) => {}) {
    const item = document.createElement(tag);
    if(cls){
        item.className = cls;
    }
    patch(item);
    return item;
}

export function createCircleBar(percent, color)
{
    let circle = document.createElement('div');
    circle.className='c100 p' + percent + ' ' + color;
    circle.style='margin: 0 auto;';
    circle.innerHTML='  <span>'+ percent + '%</span>\n' +
        '  <div class="slice">\n' +
        '    <div class="bar"></div>\n' +
        '    <div class="fill"></div>\n' +
        '  </div>';
    return circle;
}
function randomInteger(min, max) {
    // получить случайное число от (min-0.5) до (max+0.5)
    let rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
}

export function createDL(o) {
    var dl = createItem('dl', o.dl_cls || null),
        dt = createItem('dt', o.dt_cls || null),
        dd = createItem('dd', o.dd_cls || null);

    dl.appendChild(dt);
    dt.innerHTML = o.dt_text || '';

    dl.appendChild(dd);
    dd.innerHTML = o.dd_text || '';

    return dl;
}

function loadPage(url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
            cb(xhr.responseText);
        }
    };

    xhr.open('GET', url, true);
    xhr.send(null);
}

export function refreshToken(cb) {
    console.log(getEnv('DEBUG_MODE') == false);
    // let cookie = document.cookie.split(';').reduce((cookieObject, cookieString) => {
    //     let splitCookie = cookieString.split('=')
    //     try {
    //         cookieObject[splitCookie[0].trim()] = decodeURIComponent(splitCookie[1])
    //     } catch (error) {
    //         cookieObject[splitCookie[0].trim()] = splitCookie[1]
    //     }
    //     return cookieObject
    // }, [])


    if (localStorage.getItem('hdx_auth'))  {

        // if(!cookie["jwt-access"]) {
        //     logout(function () {
        //         checkAuthToken();
        //         cb();
        //     });
        // }
        var data = JSON.parse(localStorage.getItem('hdx_auth'));
        ACCESS_TOKEN = data.access;
        if(data.refresh) {
            xhrPost(getBaseApiUrl() + '/auth/local/refresh', {refresh: data.refresh}, function (r) {
                if (r.token) {
                    data.access = r.token;
                    setAuthToken(data);
                    cb();
                } else {
                    logout(function () {
                        checkAuthToken();
                        cb();
                    });
                }
            })
        }
        else {
            checkAuthToken();
            cb();
        }
    }
    else {
        checkAuthToken();
        cb();
    }
}

function checkAuthToken() {
    // let cookie = document.cookie.split(';').reduce((cookieObject, cookieString) => {
    //     let splitCookie = cookieString.split('=')
    //     try {
    //         cookieObject[splitCookie[0].trim()] = decodeURIComponent(splitCookie[1])
    //     } catch (error) {
    //         cookieObject[splitCookie[0].trim()] = splitCookie[1]
    //     }
    //     return cookieObject
    // }, [])
    //
    // if(cookie["jwt-access"]) {
    //     if(!localStorage.getItem('hdx_auth')) {
    //         localStorage.setItem('hdx_auth', `{"access":"${cookie["jwt-access"]}"}`);
    //     }
    //
    // }
    if (localStorage.getItem('hdx_auth'))  {
        ACCESS_TOKEN = JSON.parse(localStorage.getItem('hdx_auth')).access;
        setIsAuthorized(true)
        getNavSidebar().renderContent('authed');
    }
    else {
        setIsAuthorized(false);
        getNavSidebar().renderContent('unauthed');
    }

    getNavSidebar().addAvatar();
}

export function setAuthToken(auth_data, cb) {
    // var cookieString = 'jwt-access=' + auth_data.access;
    // if (auth_data.refresh) {
    //     cookieString += '; jwt-refresh=' + auth_data.refresh;
    // }
    // cookieString += ';path=/';
    // cookieString += ';domain=.humandesignx.com';

    ACCESS_TOKEN = auth_data.access;
    localStorage.setItem('hdx_auth', JSON.stringify(auth_data));
    // document.cookie = cookieString;
    checkAuthToken();

    if (cb) {
        cb();
    }
}

export function logout(cb) {
    localStorage.removeItem('hdx_auth');

    // document.cookie = 'jwt-access=; jwt-refresh=;path=/;domain=.humandesignx.com';
    ACCESS_TOKEN = null;
    setIsAuthorized(false);
    getNavSidebar().renderContent('unauthed');
    if(localStorage.getItem('mobileApp')) {
        window.location='/mobjasonapp/homebg.html'
    } else {
        checkAuthToken();
        if (cb) {
            cb();
        }
    }
}

function checkTemporaryCookie(name) {
    var now = Date.now(),
        old,
        c;

    //console.log(name)

    if (!localStorage[name]) {
        return false;
    }
    else {
        c = JSON.parse(localStorage[name]);
        old = c.setTime;

        if (now - old > c.time) {
            return false;
        }
    }

    return true;
}


export function getTemporaryCookie(name) {
    var now = Date.now(),
        old,
        c;

    //console.log(name)

    if (!localStorage[name]) {
        return null;
    }
    else {
        c = JSON.parse(localStorage[name]);
        old = c.setTime;

        if (now - old > c.time) {
            return null;
        } else {
            return c.data;
        }
    }
}

export function setTemporaryCookie(name, data, time) {
    var wrapper = {
        setTime: Date.now(),
        time: time,
        data: data
    };
    localStorage[name] = JSON.stringify(wrapper);
}

export function uploadImg(file, fileName, url, cb) {
    var data = new FormData();

    data.append(fileName, file);

    xhrPut(getBaseApiUrl() + url, data, function (r) {
        if (r.path) {
            cb(r.path);
        }
    });

}

export function getBaseApiUrl() {
    return getEnv('API_BASE_URL') + '/' + getCurrentLanguage()
}

function xhrGet(url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (this.readyState === 4) {
            if (this.status >= 200 && this.status < 300) {
                cb(JSON.parse(xhr.responseText));
            } else {
                cb({'error': true, 'error_code': this.status});
            }
        }
    };

    xhr.open('GET', url, true);
    if (ACCESS_TOKEN) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + ACCESS_TOKEN);
    }
    xhr.send(null);
}
//dateStr like dd/mm/yyyy
function xhrGetUSDCb(cb) {
    var url ='/cbr/daily_json.js';
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (this.readyState === 4) {
            if (this.status >= 200 && this.status < 300) {
                cb(JSON.parse(xhr.responseText));
            } else {
                cb({'error': true, 'error_code': this.status});
            }
        }
    };
    xhr.open('GET', url, true);
    xhr.send(null);
}


function xhrGetSimple(url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (this.readyState === 4) {
            if (this.status >= 200 && this.status < 300) {
                cb(xhr.responseText);
            }
            else {
                cb({'error': true, 'error_code': this.status})
            }
        }
    };

    xhr.open('GET', url, true);
    if (ACCESS_TOKEN) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + ACCESS_TOKEN);
    }
    xhr.send(null);
}

function xhrGetObj(url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (this.readyState === 4) {
            if (this.status >= 200 && this.status < 300) {
                cb(xhr.responseText);
            }
            else {
                cb({'error': true, 'error_code': this.status})
            }
        }
    };

    xhr.open('GET', url, true);
    // if (ACCESS_TOKEN) {
    //     xhr.setRequestHeader('Authorization', 'Bearer ' + ACCESS_TOKEN);
    // }
    xhr.send(null);
}

function xhrPost(url, data, cb) {
    //console.log(data)
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (this.readyState === 4) {
            if (this.status >= 200 && this.status < 300) {
                var r = {};
                if(xhr.responseText.length){
                    r = JSON.parse(xhr.responseText);
                }

                cb(r);
            }
            else {
                try {
                    if(xhr.responseText.length){
                        r = JSON.parse(xhr.responseText);
                    }
                } catch(e) {
                    if (e) { console.error(e); }
                }
                cb({'error': true, 'error_code': this.status, 'details': r.details || [] })

            }
        }
    };

    xhr.open('POST', url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    if (ACCESS_TOKEN) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + ACCESS_TOKEN);
    }
    xhr.send(JSON.stringify(data));
}

function xhrPut(url, data, cb) {
    //console.log(data)
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (this.readyState === 4) {
            if (this.status >= 200 && this.status < 300) {
                var r = {};
                if(xhr.responseText.length){
                    r = JSON.parse(xhr.responseText);
                }

                cb(r);
            }
            else {
                cb({'error': true, 'error_code': this.status})

            }
        }
    };

    xhr.open('PUT', url, true);
    //xhr.setRequestHeader("Content-Type", "application/json");
    if (ACCESS_TOKEN) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + ACCESS_TOKEN);
    }
    xhr.send(data);
}
function xhrPatch(url, data, cb) {
    //console.log(data)
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (this.readyState === 4) {
            if (this.status >= 200 && this.status < 300) {
                var r = {};
                if(xhr.responseText.length){
                    r = JSON.parse(xhr.responseText);
                }

                cb(r);
            }
            else {
                cb({'error': true, 'error_code': this.status })

            }
        }
    };

    xhr.open('PATCH', url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    if (ACCESS_TOKEN) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + ACCESS_TOKEN);
    }
    xhr.send(JSON.stringify(data));
}

function xhrDelete(url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (this.readyState === 4) {
            if (this.status >= 200 && this.status < 300) {
                var r = {};
                if(xhr.responseText.length){
                    r = JSON.parse(xhr.responseText);
                }

                cb(r);
            }
            else {
                cb({'error': true, 'error_code': this.status})

            }
        }
    };

    xhr.open('DELETE', url, true);
    //xhr.setRequestHeader("Content-Type", "application/json");
    if (ACCESS_TOKEN) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + ACCESS_TOKEN);
    }
    xhr.send(null);
}

function xhrGetIcon(url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
            cb(xhr.responseText);
        }
    };

    xhr.open('GET', url, true);
    xhr.send(null);
}

export function obj2qs(obj) {
    var str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    }

    return str.sort((a, b) => a-b).join("&");
}

export function parseQS(qs) {
    var obj = {};

    qs.split('&').forEach(function (e) {
        obj[e.split('=')[0]] = e.split('=')[1];
    });

    return obj;
}

export function filterObj (obj, extraFields = [], replaces = {}) {
    return ['year', 'month', 'placeId', 'day', 'hours', 'minutes'].concat(extraFields).reduce(function(acc, v) {
        if (obj[v]) {
            if (replaces[v]) {
                acc[replaces[v]] = obj[v];
            } else {
                acc[v] = obj[v];
            }
        }
        return acc;
    }, {});
}

function isRetina(){
    var mediaQuery = "(-webkit-min-device-pixel-ratio: 1.5),\
            (min--moz-device-pixel-ratio: 1.5),\
            (-o-min-device-pixel-ratio: 3/2),\
            (min-resolution: 1.5dppx)";

    if (window.devicePixelRatio > 1 || window.matchMedia && window.matchMedia(mediaQuery).matches){
        return true;
    }
    return false;
}

export function updateObj(obj1, obj2){
    var obj3 = {};
    for (var attrname in obj1) { obj3[attrname] = obj1[attrname]; }
    for (var attrname in obj2) { obj3[attrname] = obj2[attrname]; }
    return obj3;
}

export function arr2obj(arr, key) {
    var obj = {};
    arr.forEach(function (e) {
        obj[e[key]] = e;
    });

    return obj;
}

function getColorSeq(colorStart, colorEnd, steps) {
    var redStep = (colorStart[0] - colorEnd[0]) / steps,
        greenStep = (colorStart[1] - colorEnd[1]) / steps,
        blueStep = (colorStart[2] - colorEnd[2]) / steps,
        seq = [];

    for (var i=0;i<=steps;i++) {
        seq.push([
            Math.round(colorStart[0] - redStep * i),
            Math.round(colorStart[1] - greenStep * i),
            Math.round(colorStart[2] - blueStep * i)
        ]);
    }

    return seq;
}

export function animateColor(colorSeq, time, colorChange, complete) {
    var counter = 0,
        int;

    var changeColor = function () {

        colorChange(colorSeq[counter]);
        counter += 1;

        if (counter === colorSeq.length) {
            clearInterval(int);
            complete();
        }
    };

    int = setInterval(changeColor, time);
}

export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function make2digits(num) {
    if (num.toString().length === 1) {
        return 0 + num;
    }

    return num;
}

export function isIPhoneX() {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
        ratio = window.devicePixelRatio || 1,
        screen = {
            width : window.screen.width * ratio,
            height : window.screen.height * ratio
        };

    if (iOS && screen.width === 1125 && screen.height === 2436) {
        return true;
    }

    return false;
}

export function isIOS () {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function isAndroid() {
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    return isAndroid;
}
export function isIPhone () {
    return /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

/*
// анимация цвета
var colors = getColorSeq([0,0,0],[255,255,255],100);

animateColor(colors, 10, function (rgb) {
    console.log(rgb);
}, function () {
    console.log(123);
});
*/

export function payWidget(paymentData, handlers = {}) {
    //console.log(paymentData)
    var payHandler = function () {
        if(getCurrentLanguage() === 'en')
        {
            xhrGetUSDCb(function (res) {
                if(!res.error)
                {
                    let usd=res.Valute.USD.Value;
                    paymentData.payment_amount = Number(Number(paymentData.payment_amount)*Number(usd)).toFixed(2);
                }
            });
        }

        CloudpaymentsCharge(paymentData,
            lang('Оплата подписки в humandesignx.com'),
            lang("Подписка в humandesignx.com")
        ).then(() => {
            //console.log('success payment', options);
            if (handlers.onSuccess) {
                handlers.onSuccess();
            } else {
                if (CUR_PAGE.name === 'compute') {
                    var qs;
                    if (document.location.search.length > 1) {
                        var q = parseQS(document.location.search.substring(1));
                        q['hub'] = true;
                        qs = obj2qs(q);
                    } else {
                        qs = obj2qs({hub: true});
                    }
                    page(window.location.pathname + '?' + qs);
                } else {
                    if(localStorage['hdx_url_after_plan_buy']) {
                        window.location=localStorage['hdx_url_after_plan_buy'];
                        localStorage.removeItem('hdx_url_after_plan_buy');
                    } else {
                        page('/account/charts');
                    }
                }
            }
        }).catch(() => {
            //console.log('fail payment', reason, options);
            if (handlers.onFail) {
                handlers.onFail();
            } else {
                if (reason !== 'User has cancelled') {
                    alert('Произошла ошибка: ' + lang(reason, true));
                }
            }
        })
    };

    payHandler();
}

export function throttle(func, ms) {
    var isThrottled = false,
      savedArgs,
      savedThis;

    function wrapper() {

      if (isThrottled) { // (2)
        savedArgs = arguments;
        savedThis = this;
        return;
      }

      func.apply(this, arguments); // (1)

      isThrottled = true;

      setTimeout(function() {
        isThrottled = false; // (3)
        if (savedArgs) {
          wrapper.apply(savedThis, savedArgs);
          savedArgs = savedThis = null;
        }
      }, ms);
    }

    return wrapper;
}

function _isString(value) {
    const type = typeof value
    return type == 'string' || (type == 'object' && value != null && !Array.isArray(value) && getTag(value) == '[object String]')
}

export function padStart(v, size, symbol = '0') {
    var s = v.toString();
    while (s.length < size) s = symbol + s;
    return s;
}

export function removeAllListeners(el, eventName) {
    // TODO: fix remove listeners
    // var listeners = el.getEventListeners(eventName);
    // for (var i = 0; i < listeners.length; i++) {
    //     el.removeEventListener('scroll', listeners[i].listener);
    // }
}

export function getSiblings(el) {
    const siblings = [];
    let sibling = el.parentNode.firstChild;
    for (; sibling; sibling = sibling.nextElementSibling) {
      if (sibling !== el) {
        siblings.push(sibling);
      }
    }
    return siblings;
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
};

export function scrollToGen(to, duration, progress, cb) {
    var start = document.scrollingElement.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function(){
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        document.scrollingElement.scrollTop = val;
        progress();
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
        else {
            cb();
        }
    };
    animateScroll();
}

export {
    xhrGet,
    xhrPut,
    xhrPost,
    xhrPatch,
    xhrDelete,
    xhrGetIcon,
    xhrGetObj,
    xhrGetSimple,
    xhrGetUSDCb,
};
