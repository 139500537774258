import page from "page";
import { initIsFromFactsToSeo, setIsFromFactsToSeo } from "./common";
import { isAuthorized } from "./shared/auth";
import { getEnv } from "./shared/env";
import { clearAllIntervals } from "./shared/intervals";
import { addCurrentLanguageListener, lang } from "./shared/lang";
import { getPageOptions, setPageOptions } from "./shared/page_options";
import { loadCloudpayments } from "./shared/payments/cloudpayments";
import { addCurrentThemeListener } from "./shared/theme";
import { getBaseApiUrl, xhrGet } from "./shared/utils";
import { setRefferalFromQS } from "./shared/referrals";

var CUR_PAGE = {
        name: null,
        view: null,
    },
    PAGE_TITLES;

const xhrGetPages = (cb) => {
    let r = window.hydrateVars?.PAGE_TITLES;
    if (r) {
        if (getEnv("DEBUG_MODE")) {
            console.log("PAGE_TITLES from hydrateVars");
        }
        cb(r);
        return;
    }
    xhrGet(getBaseApiUrl() + "/pages", cb);
};

export function onNavigate() {
    page();
}

export function initRouting() {
    loadCloudpayments();
    setRefferalFromQS();

    addCurrentThemeListener(function (theme) {
        if (CUR_PAGE.view && CUR_PAGE.view.changeTheme && (CUR_PAGE.name === "profile" || CUR_PAGE.name === "compute")) {
            CUR_PAGE.view.changeTheme(theme);
        }
        window.location.reload();
    });

    addCurrentLanguageListener(function (language) {
        if (getEnv("IS_LANG_URLS")) {
            const newLangUrl = getEnv(`${language.toUpperCase()}_URL`);
            if (newLangUrl && window.location.origin !== newLangUrl) {
                window.location.replace(
                    `${newLangUrl}${window.location.pathname}${window.location.search}${window.location.hash}`
                );
            }
        } else {
            window.location.reload();
        }

        if (!CUR_PAGE.view) {
            return;
        }
        if (CUR_PAGE.name === "compute") {
            CUR_PAGE.view.localizeText();
            // TODO: refactoring - to be dont need of redraw call
            // May be need test that already working without this call
            CUR_PAGE.view.redraw(getPageOptions());
        }
    });

    //TODO: move it out
    initIsFromFactsToSeo();

    xhrGetPages(function (r) {
        PAGE_TITLES = r;
        //console.log(LANG);

        page.base("/");

        if (getEnv("SUBSCRIPTIONS_PAGE")) {
            page("subscriptions", function (ctx) {
                updateOptions(
                    {
                        pathname: ctx.pathname,
                        page: "subscriptions_new",
                        type: "defaut",
                        title: PAGE_TITLES["/subscriptions"]
                            ? PAGE_TITLES["/subscriptions"]
                            : lang("HDX - Подписки", true),
                    },
                    ctx.querystring
                );
                viewLauncher("subscriptions_new");
            });
        }

        if (getEnv("SUBSCRIPTIONS_PAGE")) {
            page("membership", function (ctx) {
                updateOptions(
                    {
                        pathname: ctx.pathname,
                        page: "subscriptions_new",
                        type: "defaut",
                        title: PAGE_TITLES["/membership"]
                            ? PAGE_TITLES["/membership"]
                            : lang("HDX - Подписки", true),
                    },
                    ctx.querystring
                );
                viewLauncher("subscriptions_new");
            });
        }

        page("chart/:id", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "chart",
                    type: "basic-read",
                    data: ctx.params,
                    dynamic_url: true,
                    title: PAGE_TITLES["/chart"]
                        ? PAGE_TITLES["/chart"]
                        : lang("HDX - Сохраненная Карта", true),
                },
                ctx.querystring
            );
            viewLauncher("compute");
            // updateOptions({'pathname': ctx.pathname, 'page': 'chart-read', 'type': 'basic-read-view', 'data': ctx.params, 'dynamic_url': true, 'title':PAGE_TITLES['/chart'] ? PAGE_TITLES['/chart'] :  lang('HDX - Просмотр карты', true)}, ctx.querystring);
            // viewLauncher('compute-read');
        });
        page("example_chart/:id", function (ctx) {
            updateOptions(
                {
                    is_example: true,
                    pathname: ctx.pathname,
                    page: "chart",
                    type: "basic-read",
                    data: ctx.params,
                    dynamic_url: true,
                    title: PAGE_TITLES["/chart"]
                        ? PAGE_TITLES["/chart"]
                        : lang("HDX - Сохраненная Карта", true),
                },
                ctx.querystring
            );
            viewLauncher("compute");
            // updateOptions({'is_example' : true, 'pathname': ctx.pathname, 'page': 'chart-read', 'type': 'basic-read-view', 'data': ctx.params, 'dynamic_url': true, 'title':PAGE_TITLES['/chart'] ? PAGE_TITLES['/chart'] :  lang('HDX - Просмотр карты', true)}, ctx.querystring);
            // viewLauncher('compute-read');
        });
        if (getEnv("COMPOSITE_PAGE")) {
            page("composite/:id", function (ctx) {
                updateOptions(
                    {
                        pathname: ctx.pathname,
                        page: "chart-composite-read",
                        type: "composite-basic-read",
                        data: ctx.params,
                        dynamic_url: true,
                        title: PAGE_TITLES["/composite"]
                            ? PAGE_TITLES["/composite"]
                            : lang("HDX - Карта совместимости", true),
                    },
                    ctx.querystring
                );
                viewLauncher("compute-composite-read");
            });
        }
        page("example_composite/:id", function (ctx) {
            updateOptions(
                {
                    is_example: true,
                    pathname: ctx.pathname,
                    page: "chart",
                    type: "composite-basic-read",
                    data: ctx.params,
                    dynamic_url: true,
                    title: PAGE_TITLES["/composite"]
                        ? PAGE_TITLES["/composite"]
                        : lang("HDX - Карта совместимости", true),
                },
                ctx.querystring
            );
            viewLauncher("compute");
        });
        if (getEnv("COMPOSITE_PAGE")) {
            page("composite/reading/:id", function (ctx) {
                updateOptions(
                    {
                        pathname: ctx.pathname,
                        page: "profile",
                        type: "composite-read",
                        data: ctx.params,
                        dynamic_url: true,
                        title: PAGE_TITLES["/composite/reading"]
                            ? PAGE_TITLES["/composite/reading"]
                            : lang("HDX - Прочтение - Совместимость", true),
                    },
                    ctx.querystring
                );
                viewLauncher("profile");
            });
        }
        page("chart/:id/view", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "chart-read",
                    type: "basic-read-view",
                    data: ctx.params,
                    dynamic_url: true,
                    title: PAGE_TITLES["/chart"]
                        ? PAGE_TITLES["/chart"]
                        : lang("HDX - Просмотр карты", true),
                },
                ctx.querystring
            );
            viewLauncher("compute-read");
        });
        page("example_chart/:id/view", function (ctx) {
            updateOptions(
                {
                    is_example: true,
                    pathname: ctx.pathname,
                    page: "chart-read",
                    type: "basic-read-view",
                    data: ctx.params,
                    dynamic_url: true,
                    title: PAGE_TITLES["/chart"]
                        ? PAGE_TITLES["/chart"]
                        : lang("HDX - Просмотр карты", true),
                },
                ctx.querystring
            );
            viewLauncher("compute-read");
        });
        // page('account/reading/:id', function(ctx){
        //     updateOptions({'pathname': ctx.pathname, 'page': 'profile', 'type': 'full-read', 'data': ctx.params, 'dynamic_url': true, 'title': PAGE_TITLES['/account/reading/:id'] ? PAGE_TITLES['/account/reading/:id'] : lang('HDX - Расшифровка карты', true)}, ctx.querystring);
        //     viewLauncher('profile');
        // });
        page("account/reading/:id", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "chart-read",
                    type: "basic-read-view",
                    data: ctx.params,
                    dynamic_url: true,
                    title: PAGE_TITLES["/chart"]
                        ? PAGE_TITLES["/chart"]
                        : lang("HDX - Просмотр карты", true),
                },
                ctx.querystring
            );
            viewLauncher("compute-read");
        });
        page("example_reading/:id", function (ctx) {
            updateOptions(
                {
                    is_example: true,
                    pathname: ctx.pathname,
                    page: "chart-read",
                    type: "basic-read-view",
                    data: ctx.params,
                    dynamic_url: true,
                    title: PAGE_TITLES["/chart"]
                        ? PAGE_TITLES["/chart"]
                        : lang("HDX - Просмотр карты", true),
                },
                ctx.querystring
            );
            viewLauncher("compute-read");
        });
        // page('example_reading/:id', function(ctx){
        //     updateOptions({'is_example' : true, 'pathname': ctx.pathname, 'page': 'profile', 'type': 'full-read', 'data': ctx.params, 'dynamic_url': true, 'title': PAGE_TITLES['/account/reading/:id'] ? PAGE_TITLES['/account/reading/:id'] : lang('HDX - Расшифровка карты', true)}, ctx.querystring);
        //     viewLauncher('profile');
        // });
        page("chart", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "chart",
                    type: "defaut",
                    title: PAGE_TITLES["/chart"]
                        ? PAGE_TITLES["/chart"]
                        : lang("HDX - Расчет бодиграфа", true),
                },
                ctx.querystring
            );
            viewLauncher("compute");
            // updateOptions({'pathname': ctx.pathname, 'page': 'chart-read', 'type': 'basic-read-view', 'data': ctx.params, 'dynamic_url': true, 'title':PAGE_TITLES['/chart'] ? PAGE_TITLES['/chart'] :  lang('HDX - Просмотр карты', true)}, ctx.querystring);
            // viewLauncher('compute-read');
            //      updateOptions({'pathname': ctx.pathname, 'page': 'chart', 'type': 'defaut', 'title': PAGE_TITLES['/chart'] ? PAGE_TITLES['/chart'] : lang('HDX - Расчет бодиграфа', true)}, ctx.querystring);
            //      viewLauncher('compute');
        });
        page("chart_reading", function (ctx) {
            // updateOptions({'pathname': ctx.pathname, 'page': 'chart', 'type': 'defaut', 'title': PAGE_TITLES['/chart'] ? PAGE_TITLES['/chart'] : lang('HDX - Расчет бодиграфа', true)}, ctx.querystring);
            // viewLauncher('compute');
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "chart-read",
                    type: "basic-read-view",
                    data: ctx.params,
                    dynamic_url: true,
                    title: PAGE_TITLES["/chart"]
                        ? PAGE_TITLES["/chart"]
                        : lang("HDX - Просмотр карты", true),
                },
                ctx.querystring
            );
            //
            viewLauncher("compute-read");
            //      updateOptions({'pathname': ctx.pathname, 'page': 'chart', 'type': 'defaut', 'title': PAGE_TITLES['/chart'] ? PAGE_TITLES['/chart'] : lang('HDX - Расчет бодиграфа', true)}, ctx.querystring);
            //      viewLauncher('compute');
        });
        page("r/:name", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "chart",
                    type: "referral",
                    data: ctx.params,
                    dynamic_url: true,
                    title: PAGE_TITLES["/r/:slug"]
                        ? PAGE_TITLES["/r/:slug"]
                        : lang("HDX - Расчет бодиграфа", true),
                },
                ctx.querystring
            );
            viewLauncher("compute");
        });
        page("p/:name", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "chart",
                    type: "partner",
                    data: ctx.params,
                    dynamic_url: true,
                    title: PAGE_TITLES["/p/:slug"]
                        ? PAGE_TITLES["/p/:slug"]
                        : lang("HDX - Расчет бодиграфа", true),
                },
                ctx.querystring
            );
            viewLauncher("compute");
        });

        page("login", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "auth",
                    type: "auth",
                    title: PAGE_TITLES["/login"]
                        ? PAGE_TITLES["/login"]
                        : lang("HDX - Вход", true),
                },
                ctx.querystring
            );
            viewLauncher("auth");
        });
        page("signup", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "signup",
                    type: "signup",
                    title: PAGE_TITLES["/signup"]
                        ? PAGE_TITLES["/signup"]
                        : lang("HDX - Регистрация", true),
                },
                ctx.querystring
            );
            viewLauncher("auth");
        });
        page("logout", function (ctx) {
            updateOptions(
                { pathname: ctx.pathname, page: "text-page", type: "logout" },
                ctx.querystring
            );
            viewLauncher("text-page");
        });

        page("auth/set-password", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "reset-password",
                    type: "reset-password",
                    title: PAGE_TITLES["/auth/set-password"]
                        ? PAGE_TITLES["/auth/set-password"]
                        : lang("HDX - Новый пароль", true),
                },
                ctx.querystring
            );
            viewLauncher("reset-password");
        });
        page("auth/signup", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "complete-signup",
                    type: "complete-signup",
                    title: PAGE_TITLES["/auth/signup"]
                        ? PAGE_TITLES["/auth/signup"]
                        : lang("HDX - Завершение Регистрации", true),
                },
                ctx.querystring
            );
            viewLauncher("complete-signup");
        });

        page("terms", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "text-page",
                    type: "terms",
                    title: PAGE_TITLES["/terms"]
                        ? PAGE_TITLES["/terms"]
                        : lang("HDX - Пользовательское соглашение", true),
                },
                ctx.querystring
            );
            viewLauncher("text-page");
        });
        page("unsubscribe", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "text-page",
                    type: "unsubscribe",
                    title: PAGE_TITLES["/unsubscribe"]
                        ? PAGE_TITLES["/unsubscribe"]
                        : lang("Human Design X", true),
                },
                ctx.querystring
            );
            viewLauncher("text-page");
        });
        page("subscription/success", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "text-page",
                    type: "subscription-status",
                    data: ctx.params,
                    title: PAGE_TITLES["/subscriptions/success"]
                        ? PAGE_TITLES["/subscriptions/success"]
                        : lang("HDX - Статус покупки", true),
                },
                ctx.querystring
            );
            viewLauncher("text-page");
        });
        page("payment/:status", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "text-page",
                    type: "payment-status",
                    data: ctx.params,
                    dynamic_url: true,
                    title: PAGE_TITLES["/payment/:status"]
                        ? PAGE_TITLES["/payment/:status"]
                        : lang("HDX - Статус покупки", true),
                },
                ctx.querystring
            );
            viewLauncher("text-page");
        });

        page("payment-status", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "text-page",
                    type: "payment-result",
                    data: ctx.params,
                    dynamic_url: true,
                    title: PAGE_TITLES["/payment-status"]
                        ? PAGE_TITLES["/payment-status"]
                        : lang("HDX - Статус покупки", true),
                },
                ctx.querystring
            );
            viewLauncher("text-page");
        });

        page("/", function () {
            updateOptions({
                page: "landing",
                title: PAGE_TITLES["/"]
                    ? PAGE_TITLES["/"]
                    : lang("Human Design X - Дизайн Человека", true),
            });
            viewLauncher("landing");
        });

        page("/mobileapp", function () {
            localStorage.setItem("mobileApp", "true");
            updateOptions({
                page: "landing",
                title: PAGE_TITLES["/"]
                    ? PAGE_TITLES["/"]
                    : lang("Human Design X - Дизайн Человека", true),
            });
            viewLauncher("landing");
        });

        page("account", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "profile",
                    data: ctx.params,
                    title: PAGE_TITLES["/account"]
                        ? PAGE_TITLES["/account"]
                        : lang("HDX - Личный кабинет", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });

        if (getEnv("TEAM_MEMBER_PAGE")) {
            page("team-member/:id", function (ctx) {
                updateOptions(
                    {
                        pathname: ctx.pathname,
                        page: "profile",
                        type: "team_member",
                        data: ctx.params,
                        title: PAGE_TITLES["/team-member"]
                            ? PAGE_TITLES["/team-member"]
                            : lang("HDX - Команда - Просмотр участника", true),
                    },
                    ctx.querystring
                );
                viewLauncher("profile");
            });
        }
        page("example_team-member/:id", function (ctx) {
            updateOptions(
                {
                    is_example: true,
                    pathname: ctx.pathname,
                    page: "team_member",
                    type: "team_member",
                    data: ctx.params,
                    title: PAGE_TITLES["/team-member"]
                        ? PAGE_TITLES["/team-member"]
                        : lang("HDX - Команда - Просмотр участника", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        page("account/charts", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "charts",
                    data: ctx.params,
                    title: PAGE_TITLES["/account/charts"]
                        ? PAGE_TITLES["/account/charts"]
                        : lang("HDX - Мои карты", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        if (getEnv("COMPOSITE_PAGE")) {
            page("account/composites", function (ctx) {
                updateOptions(
                    {
                        pathname: ctx.pathname,
                        page: "profile",
                        type: "composites",
                        data: ctx.params,
                        title: PAGE_TITLES["/account/composites"]
                            ? PAGE_TITLES["/account/composites"]
                            : lang("HDX - Совместимости", true),
                    },
                    ctx.querystring
                );
                viewLauncher("profile");
            });
        }
        page("account/forecasts", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "forecasts",
                    data: ctx.params,
                    title: PAGE_TITLES["/account/forecasts"]
                        ? PAGE_TITLES["/account/forecasts"]
                        : lang("Прочтение - Cycles", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        page("account/forecasts/:id", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "forecasts",
                    data: ctx.params,
                    title: PAGE_TITLES["/account/forecasts"]
                        ? PAGE_TITLES["/account/forecasts"]
                        : lang("Прочтение - Cycles", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        page("example_forecasts/:id", function (ctx) {
            updateOptions(
                {
                    is_example: true,
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "forecasts",
                    data: ctx.params,
                    title: PAGE_TITLES["/account/forecasts"]
                        ? PAGE_TITLES["/account/forecasts"]
                        : lang("Прочтение - Cycles", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        page("account/orders", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "orders",
                    data: ctx.params,
                    title: PAGE_TITLES["/account/orders"]
                        ? PAGE_TITLES["/account/orders"]
                        : lang("HDX - Мои заказы", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        page("account/friends", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "partner",
                    data: ctx.params,
                    title: PAGE_TITLES["/account/friends"]
                        ? PAGE_TITLES["/account/friends"]
                        : lang("HDX - Дружеские возможности", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        page("talent/:id", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "new_talents",
                    data: ctx.params,
                    title: PAGE_TITLES["/talent/:id"]
                        ? PAGE_TITLES["/talent/:id"]
                        : lang("Талант", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });

        page("account/talents/:id/:key?", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "talents",
                    data: ctx.params,
                    title: PAGE_TITLES["/account/talents/:id"]
                        ? PAGE_TITLES["/account/talents/:id"]
                        : lang("Прочтение - Ключи жизни", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        page("account/talents/:id/:planet_name/gene-key", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "keys",
                    data: ctx.params,
                    title: PAGE_TITLES["/account/talents/:id/:planet_name"]
                        ? PAGE_TITLES["/account/talents/:id/:planet_name"]
                        : lang("Ключ к таланту", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        page("example_talents/:id/:key?", function (ctx) {
            updateOptions(
                {
                    is_example: true,
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "talents",
                    data: ctx.params,
                    title: PAGE_TITLES["/account/talents/:id"]
                        ? PAGE_TITLES["/account/talents/:id"]
                        : lang("Прочтение - Ключи жизни", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        page("example_talents/:id/:planet_name/gene-key", function (ctx) {
            updateOptions(
                {
                    is_example: true,
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "keys",
                    data: ctx.params,
                    title: PAGE_TITLES["/account/talents/:id/:planet_name"]
                        ? PAGE_TITLES["/account/talents/:id/:planet_name"]
                        : lang("Ключ к таланту", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        if (getEnv("TEAMS_SERVICE_ENABLED")) {
            page("account/team/:id", function (ctx) {
                updateOptions(
                    {
                        pathname: ctx.pathname,
                        page: "profile",
                        type: "team_new",
                        data: ctx.params,
                        dynamic_url: true,
                        title: PAGE_TITLES["/account/team/:id"]
                            ? PAGE_TITLES["/account/team/:id"]
                            : lang("HDX - Команда", true),
                    },
                    ctx.querystring
                );
                viewLauncher("profile");
            });
            page("example_team/:id", function (ctx) {
                updateOptions(
                    {
                        is_example: true,
                        pathname: ctx.pathname,
                        page: "profile",
                        type: "team_new",
                        data: ctx.params,
                        dynamic_url: true,
                        title: PAGE_TITLES["/account/team/:id"]
                            ? PAGE_TITLES["/account/team/:id"]
                            : lang("HDX - Команда", true),
                    },
                    ctx.querystring
                );
                viewLauncher("profile");
            });
            page("account/my_team/:id", function (ctx) {
                updateOptions(
                    {
                        pathname: ctx.pathname,
                        page: "profile",
                        type: "team_new",
                        data: ctx.params,
                        dynamic_url: true,
                        title: PAGE_TITLES["/account/team/:id"]
                            ? PAGE_TITLES["/account/team/:id"]
                            : lang("HDX - Команда", true),
                    },
                    ctx.querystring
                );
                viewLauncher("profile");
            });
        }
        page("account/settings/payment", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "payment-settings",
                    data: ctx.params,
                    title: PAGE_TITLES["/account/settings/payment"]
                        ? PAGE_TITLES["/account/settings/payment"]
                        : lang("HDX - Настройки оплаты", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        page("account/settings", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "profile",
                    type: "settings",
                    data: ctx.params,
                    title: PAGE_TITLES["/account/settings"]
                        ? PAGE_TITLES["/account/settings"]
                        : lang("HDX - Настройки", true),
                },
                ctx.querystring
            );
            viewLauncher("profile");
        });
        // page('types/:id', function(ctx){
        // updateOptions({'page': 'types', 'id': ctx.params.id});
        // viewLauncher('info-page');
        // });
        // page('profiles/:id', function(ctx){
        // updateOptions({'page': 'profiles', 'id': ctx.params.id});
        // viewLauncher('info-page');
        // });
        // page('reading/:id', function(ctx){
        // updateOptions({'page': 'reading', 'id': ctx.params.id});
        // viewLauncher('info-page');
        // });

        page("test/:testName", function (ctx) {
            updateOptions({ page: "test-view", type: ctx.params.testName });
            viewLauncher("test-view");
        });
        page("account/connect/:id", function (ctx) {
            updateOptions(
                {
                    pathname: ctx.pathname,
                    page: "text-page",
                    type: "connect-tg",
                    data: ctx.params,
                    dynamic_url: true,
                    title: "Human Design X Connect",
                },
                ctx.querystring
            );
            viewLauncher("text-page");
        });

        page("*", function (ctx) {
            updateOptions(
                { page: "info-page", pathname: ctx.pathname },
                ctx.querystring
            );
            viewLauncher("info-page");
        });

        page();
    });
}

function updateOptions(params, query) {
    if (params.pathname) {
        updateHreflang(params.pathname);
    } else {
        updateHreflang("");
    }

    if (!query) {
        query = "";
    }

    setPageOptions({
        params: params,
        query: query,
    });

    // if (params.dynamic_url) {
    //     xhrGet(BASE_URL + '/pages/ru' + window.location.pathname, function (r) {
    //         console.log(r)
    //     })
    // }

    if (getPageOptions().params.title) {
        document.title = getPageOptions().params.title;
    }
}

export function render404() {
    updateOptions({ page: "text-page", type: "not-found" }, "");
    viewLauncher("text-page");
}

function clubBanner(name) {
    const isHidden = getEnv("CLUB_BANNER_ENABLED") ? localStorage.getItem("clubBannerHidden") : true;
    const banner = document.querySelector('.club_banner')

    if (isHidden) {
        banner.classList.add('hidden');
        return;
    }

    if (name == "profile") {
        banner.classList.remove('hidden');
    } else {
        banner.classList.add('hidden');
    }
}

async function viewLauncher(name) {


    clearAllIntervals();
    if (CUR_PAGE.name !== name) {
        var view = null;

        let old_name = name;

        if (name === "compute-read" || name === "compute-composite-read") {
            name = "compute";
        }

        clubBanner(name);

        switch (name) {
            case "landing":
                // updateHreflang('');
                setIsFromFactsToSeo(false);
                if (isAuthorized()) {
                    page("/account");
                    break;
                }
                view = (await import("./views/home/home")).default(
                    getPageOptions()
                );
                break;
            case "compute":
                setIsFromFactsToSeo(false);
                view = (await import("./views/compute/compute")).default(
                    getPageOptions()
                );
                break;
            case "compute-composite":
                setIsFromFactsToSeo(false);
                view = (await import("./views/compute/compute")).default(
                    getPageOptions()
                );
                break;
            case "reset-password":
                setIsFromFactsToSeo(false);
                // updateHreflang('/auth/set-password');
                view = (await import("./views/reset-password")).default(
                    getPageOptions()
                );
                break;
            case "complete-signup":
                setIsFromFactsToSeo(false);
                // updateHreflang('/account');
                view = (await import("./views/complete-signup")).default(
                    getPageOptions()
                );
                break;
            case "text-page":
                //  updateHreflang('');
                setIsFromFactsToSeo(false);
                view = (await import("./views/text-page")).default(
                    getPageOptions()
                );
                break;
            case "auth":
                //  updateHreflang('/login');
                setIsFromFactsToSeo(false);
                view = (await import("./views/auth")).default(getPageOptions());
                break;
            case "profile":
                //   updateHreflang('/account');
                setIsFromFactsToSeo(false);
                view = (await import("./views/profile/profile")).default(
                    getPageOptions()
                );
                break;
            case "info-page":
                view = (await import("./views/info-page/info-page")).default(
                    getPageOptions()
                );
                break;
            case "subscriptions":
                //  updateHreflang('/subscriptions');
                setIsFromFactsToSeo(false);
                view = (
                    await import("./views/info-page/info-page")
                ).subscriptionsView(getPageOptions());
                break;
            case "subscriptions_new":
                //  updateHreflang('/membership');
                setIsFromFactsToSeo(false);
                view = (
                    await import("./views/info-page/info-page")
                ).subscriptionsViewVer3(getPageOptions());
                break;
            case "test-view":
                setIsFromFactsToSeo(false);
                view = (await import("./views/test-view")).default(
                    getPageOptions()
                );
                break;
        }

        if (localStorage["mobileApp"]) {
            document.body.classList.add("mobileApp");
        } else {
            document.body.classList.remove("mobileApp");
        }

        if (view) {
            if (CUR_PAGE.view && CUR_PAGE.view.outro) {
                CUR_PAGE.view.outro(name, function (opts) {
                    launchView(opts);
                });
            } else {
                launchView();
            }

            function launchView(opts) {
                name = old_name;
                CUR_PAGE.name = name;
                CUR_PAGE.view = view;
                CUR_PAGE.view.init(CUR_PAGE.view, opts);
            }
        }
    } else {
        //
        CUR_PAGE.view.redraw(getPageOptions());
    }
}
function updateHreflang(path = "") {
    let links = document.getElementsByTagName("link");
    for (let ii = 0; ii < links.length; ii++) {
        if (links[ii].getAttribute("hreflang")) {
            if (links[ii].getAttribute("hreflang") === "en") {
                links[ii].setAttribute("href", getEnv("EN_URL"));
            } else if (links[ii].getAttribute("hreflang") === "id") {
                links[ii].setAttribute("href", getEnv("ID_URL"));
            } else {
                links[ii].setAttribute("href", getEnv("RU_URL"));
            }
        }
    }
}
