import moment from "moment/moment";
import { getEnv } from "./env";
import { createItem, updateObj } from "./utils";

const LISTENERS = new Map();

import localesRuChart from '../locales/ru/chart.json';
import localesRuCommon from '../locales/ru/common.json';
import localesRuHome from '../locales/ru/home.json';
import localesRuInfoPage from '../locales/ru/info-page.json';
import localesRuProfile from '../locales/ru/profile.json';
import localesRuModals from '../locales/ru/modals.json';
import localesRuErrors from '../locales/ru/errors.json';

import localesEnChart from '../locales/en/chart.json';
import localesEnCommon from '../locales/en/common.json';
import localesEnHome from '../locales/en/home.json';
import localesEnInfoPage from '../locales/en/info-page.json';
import localesEnProfile from '../locales/en/profile.json';
import localesEnModals from '../locales/en/modals.json';
import localesEnErrors from '../locales/en/errors.json';

import localesIndChart from '../locales/ind/chart.json';
import localesIndCommon from '../locales/ind/common.json';
import localesIndHome from '../locales/ind/home.json';
import localesIndInfoPage from '../locales/ind/info-page.json';
import localesIndProfile from '../locales/ind/profile.json';
import localesIndModals from '../locales/ind/modals.json';
import localesIndErrors from '../locales/ind/errors.json';

const LOCALES_MAP = {
   ru: {
    'chart': localesRuChart,
    'common': localesRuCommon,
    'home': localesRuHome,
    'info-page': localesRuInfoPage,
    'profile': localesRuProfile,
    'modals': localesRuModals,
    'errors': localesRuErrors,
   },
   en: {
    'chart': localesEnChart,
    'common': localesEnCommon,
    'home': localesEnHome,
    'info-page': localesEnInfoPage,
    'profile': localesEnProfile,
    'modals': localesEnModals,
    'errors': localesEnErrors,
   },
   ind: {
    'chart': localesIndChart,
    'common': localesIndCommon,
    'home': localesIndHome,
    'info-page': localesIndInfoPage,
    'profile': localesIndProfile,
    'modals': localesIndModals,
    'errors': localesIndErrors,
   },
}

// const LOCALES_LIST = [
//     'chart',
//     'common',
//     'home',
//     'info-page',
//     'profile',
//     'modals',
//     'errors'
// ];

let CURRENT_LANGUAGE;

let LOCALIZED_TEXTS = {};

const STORAGE_LANG_KEY = '__current_language';

export function initCurrentLanguage() {
    addCurrentLanguageListener(updateLangItems)
    return setCurrentLanguage(localStorage.getItem(STORAGE_LANG_KEY) ?? getEnv('LANG'));
}

export async function setCurrentLanguage(language) {
    CURRENT_LANGUAGE = language;

    localStorage.setItem(STORAGE_LANG_KEY, language);
    document.cookie = `${STORAGE_LANG_KEY}=${language}`;

    moment.locale(CURRENT_LANGUAGE);

    LOCALIZED_TEXTS = {};

    const locales = Object.values(LOCALES_MAP[CURRENT_LANGUAGE]);
    // const locales = await Promise.all(LOCALES_LIST.map(async (file) => {
    //     return (await import(`../locales/${CURRENT_LANGUAGE}/${file}.json`)).default;
    // }));
    locales.forEach(l => {
        LOCALIZED_TEXTS = updateObj(LOCALIZED_TEXTS, l);
    });

    for (const listener of LISTENERS.values()) {
        listener(CURRENT_LANGUAGE)
    }
}

/**
 * Add listener for setCurrentTheme event
 * Returns unsubscribe function
 */
export function addCurrentLanguageListener(listener) {
    const key = Symbol();
    LISTENERS.set(key, listener);
    return function() {
        LISTENERS.delete(key)
    }
}

export function getCurrentLanguage() {
    return CURRENT_LANGUAGE;
}

function updateLangItems() {
    setTimeout(() => {
        document.querySelectorAll('.localized_item').forEach(function (el) {
            var key = el.getAttribute('data-source-text'),
                localized = LOCALIZED_TEXTS[key];

            if (LOCALIZED_TEXTS[key]) {
                el.innerHTML = localized;
            }
        });
    }, 100)
}

export function lang(item, noTag) {
    noTag = true;
    var localItem = createItem('mark', 'localized_item'),
        helper = createItem('div'),
        localized = item;

    if (LOCALIZED_TEXTS[item]) {
        localized = LOCALIZED_TEXTS[item];
    }

    localItem.setAttribute('data-source-text', item);
    localItem.innerHTML = localized;
    helper.appendChild(localItem);

    if (noTag) {
        helper.innerHTML = localized;
    }

    return helper.innerHTML;
}

export function getLangCode() {
    switch(CURRENT_LANGUAGE) {
        case 'ru':
            return 'ru-RU';
        case 'en':
        default:
            return 'en-US';
    }
}

// export async function langPrompt() {
//     var modal = modalController({inner_cls: 'naked', prompt: true}),
//         form = (await modalTemplates('lang'))({
//             onSubmit: function (lng) {
//                 switch(lng) {
//                     case 'en':
//                         window.location=EN_URL;
//                         break;
//                     case 'ru':
//                         window.location=RU_URL;
//                         break;
//                     case 'id':
//                         window.location=ID_URL;
//                         break;
//                 }
//                 modal.remove();
//             }
//         });

//     modal.container.appendChild(form);
// }
