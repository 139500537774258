import './navigation.scss'

import page from "page";

import { isAuthorized } from "../../shared/auth";
import { getEnv } from "../../shared/env";
import { getCurrentLanguage, lang, setCurrentLanguage } from "../../shared/lang";
import { getCurrentTheme, setCurrentTheme } from "../../shared/theme";
import { createItem, getBaseApiUrl, isAndroid, isIOS, logout, scrollToGen, xhrGet, xhrGetIcon, ymGoal } from "../../shared/utils";
import { modalController } from "../modal";

let NAV_SIDEBAR;

export function initNavSidebar() {
    NAV_SIDEBAR = navigationSidebar();
}

export function getNavSidebar() {
    return NAV_SIDEBAR;
}

function navigationSidebar() {
    var sidebarOpened = false,
        holder = createItem('div', 'sidebar_holder'),
        bg = createItem('div', 'sidebar_bg'),
        switcher = createItem('div', 'sidebar_switcher'),
        popup_gift = createItem('div', 'popup_gift'),
        wrapper = createItem('div', 'sidebar_wrapper'),
        inner = createItem('div', 'sidebar_inner'),
        authedLinks = [
            // {
            //     url: '',
            //     type:'calc',
            //     text: lang('Расчет карты')
            // },
            {
                url: '/account/',
                text: lang('Профиль')
                // ,
                // type:'myBodygraphMenu'
            },
            {
                url: '/account/charts',
                text: lang('Мои карты')
            },
            {
                url: '/account/forecasts',
                text: lang('Cycles')
            },
            {
                url: '/account/orders',
                text: lang('Заказы')
            },
            // {
            //     url: '/account/friends',
            //     text: lang('Для друзей')
            // },
            // {
            //     url: '',
            //     class: 'community',
            //     show: true,
            //     text: lang('Сообщество')
            // },
            {
                url: '/account/settings',
                text: lang('Настройки')
            },
            {
                type: 'logout',
                url: '/logout?next=/',
                text: lang('Выход')
            }
        ],
        notAuthedLinks = [
        {
            url: '',
            type:'calc',
            text: lang('Расчет карты')
        },
        {
            url: '',
            type:'buy',
            text: lang('Cycles')
        }
    ];

    if (getEnv('TEAMS_SERVICE_ENABLED')) {
        authedLinks.splice(3, 0, {
            url: '/account/team/new',
            text: lang('Карьера')
        });
        notAuthedLinks.splice(1, 0, {
            url: '',
            text: lang('Карьера'),
            type:'buy',
        });
    }

    if (getEnv('SHOW_SUBSCRIPTION_OFFER')) {
        authedLinks.splice(4, 0, {
            url: '/membership',
            text: lang('Подписка')
        });
    }

    if (getEnv('COMPOSITE_PAGE')) {
        authedLinks.splice(3, 0, {
            url: '/account/composites',
            text: lang('Совместимости'),
        });
        notAuthedLinks.splice(2, 0, {
            url: '',
            text: lang('Совместимости'),
            type:'buy',
        });
    };


    document.querySelector('.wrapper').appendChild(holder);
    document.body.appendChild(bg);
    bg.addEventListener('click', sidebarSwitcher, false);

    if(getEnv('GIFT_ICON'))
    {
        holder.appendChild(popup_gift);
    }
    popup_gift.style='position: absolute;top: 4px;right: 4px;width: 20px;height: 20px;background: url(\'' + getEnv('STATIC_BASE_URL') +'/gift.png\') no-repeat center;background-size: contain;cursor: pointer;';
    popup_gift.addEventListener('click',  function () {
        showBanner({ code: 'gift'})
    }, false);
    holder.appendChild(switcher);
    switcher.innerHTML = '<i></i>';
    switcher.addEventListener('click', sidebarSwitcher, false);

    holder.appendChild(wrapper);
    wrapper.appendChild(inner);

    function sidebarSwitcher() {
        sidebarOpened = !sidebarOpened;

        if (sidebarOpened) {
            holder.classList.add('opened');
            bg.classList.add('vis');
        }
        else {
            holder.classList.remove('opened');
            bg.classList.remove('vis');
        }
    }

    function renderMenuSection(arr) {
        var sect = createItem('div', 'sb_section');
        inner.appendChild(sect);

        arr.forEach(addLink);




        function addLink(el) {
            var link = createItem('a');
            if(el.url) {
                link.setAttribute('href', el.url);
            }
            if (el.class) {
                el.class.split(' ').forEach(function(e) {link.classList.add(e)});
            }
            if(el.type==='myBodygraphMenu') {
                link.classList.add('myBodygraphMenu');
            }
            link.innerHTML = el.text;
            link.addEventListener('click', linkClickHandleAsync, false);
            sect.appendChild(link);

            async function linkClickHandleAsync(e) {
                if (el.type) {
                    e.preventDefault();

                    if (el.type === 'logout') {
                        page('/logout?next=/');
                    }
                    if (el.type === 'calc') {
                        const CUR_SCROLL = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
                        const form = (await modalTemplates('computeForm'))({
                            onSubmit: function () {
                                modal.remove();
                            }
                        });
                        const modal = modalController({
                            onClose: function () {
                                scrollToGen(CUR_SCROLL, 50, () => {}, () => {});
                            }
                        });
                        modal.container.appendChild(form);
                    }
                    if (el.type === 'buy') {
                        page('/signup');
                    }
                }


                sidebarSwitcher();
            }
        }
    }

    function renderLangSection() {
        // if (!ENABLED_LANGUAGES || ENABLED_LANGUAGES.length <= 1) {
        //     return;
        // }
        var sect = createItem('div', 'sb_section sb_lang');
        inner.appendChild(sect);

        console.log(getEnv('ENABLED_LANGUAGES'));
        getEnv('ENABLED_LANGUAGES').forEach(language => {
            addLink(lang(`lang.${language}`), language);
        })

        function addLink(text, l) {
            var link = createItem('a');
            // if(l==='en')
            // {
            //     link.setAttribute('href', getEnv('EN_URL'));
            // }
            // else if(l==='ind')
            // {
            //     link.setAttribute('href', getEnv('ID_URL'));
            // }
            // else
            // {
            //     link.setAttribute('href', getEnv('RU_URL'));
            // }

            link.innerHTML = text;
            link.setAttribute('data-lang', l);
            link.addEventListener('click', clickLink, false);
            sect.appendChild(link);

            if (l === getCurrentLanguage()) {
                link.classList.add('active');
            }

            function clickLink(e) {
                e.preventDefault();
                var el = e.currentTarget;
                var c_l = el.getAttribute('data-lang');
                if (getCurrentLanguage() !== c_l) {
                    setCurrentLanguage(c_l);
                }

                sect.querySelectorAll('a').forEach(function (el) {
                    var l = el.getAttribute('data-lang');

                    if (getCurrentLanguage() === l) {
                        el.classList.add('active');
                    } else {
                        el.classList.remove('active');
                    }
                });
            }
        }
    }

    function renderDayLightSection() {

        const sect = createItem('div', 'sb_section sb_lang sb_day_night');
        inner.appendChild(sect);

        addLink(lang('Ночной режим'), 'night');
        addLink(lang('Дневной режим'), 'day');


        function addLink(text, l) {
            var link = createItem('a');
            let ico = createItem('img');
            link.style='line-height: 16px;';

            if (l === 'night') {
                ico.src= getEnv('STATIC_BASE_URL') + '/i/moon.png';
            } else {
                ico.src= getEnv('STATIC_BASE_URL') + '/i/sun.png';
            }
            ico.width="16"
            ico.height="16"
            link.appendChild(ico);

            link.setAttribute('href', '#');
            link.innerHTML = link.innerHTML+text;
            link.setAttribute('data-style', l);
            link.addEventListener('click', clickLink, false);
            sect.appendChild(link);

            if (l === getCurrentTheme()) {
                link.classList.add('active');
            }

            function clickLink(e) {
                e.preventDefault();
                var el = e.currentTarget;
                var c_l = el.getAttribute('data-style');
                //c/onsole.log(el)
                if (getCurrentTheme() !== c_l) {
                    setCurrentTheme(c_l);
                    sect.querySelector('a.active')?.classList.remove('active');
                    link.classList.add('active');
                }
            }
        }
    }

    return {
        renderContent: function (type) {
            inner.innerHTML = '';

            if (type === 'authed') {
                renderMenuSection(authedLinks);
            }
            else {

                var sectButton = createItem('div', 'sb_section'),
                    btnDemo = createItem('button', 'btn_default btn_auth btn_gradient demo'),
                    btnAuth = createItem('button', 'btn_default btn_auth'),
                btnSignup = createItem('button', 'btn_default btn_auth');

                inner.appendChild(sectButton);
                // sectButton.appendChild(btnDemo);
                sectButton.appendChild(btnAuth);
                sectButton.appendChild(btnSignup);
                btnDemo.style['margin-bottom'] = '15px';
                btnSignup.style['margin-top'] = '15px';
                btnDemo.innerHTML = lang('Демо-режим');
                btnAuth.innerHTML = lang('Войти');
                btnSignup.innerHTML = lang('Регистрация');
                btnDemo.addEventListener('click', function () {
                    if(btnDemo.classList.contains('to_exit'))
                    {
                        btnDemo.innerHTML = lang('Демо-режим');
                        btnDemo.classList.remove('to_exit');
                        ymGoal('CLICK_SIDEBAR_DEMO_EXIT');
                        page('/');
                        sidebarSwitcher();
                    }
                    else
                    {
                        btnDemo.innerHTML = lang('Выйти из Демо-режима');
                        btnDemo.classList.add('to_exit');
                        ymGoal('CLICK_SIDEBAR_DEMO');
                        page('/example_chart/51196');
                        sidebarSwitcher();
                    }




                }, false);

                btnAuth.addEventListener('click', function () {
                    ymGoal('CLICK_SIDEBAR_LOGIN');
                    page('/login');
                    sidebarSwitcher();
                }, false);

                btnSignup.addEventListener('click', function () {
                    ymGoal('CLICK_SIDEBAR_SIGNUP');
                    page('/signup');
                    sidebarSwitcher();
                }, false);

               // renderMenuSection(notAuthedLinks);
            }

            renderLangSection();
            renderDayLightSection();

            if (isIOS() && getEnv('APPLE_BANNER_URL')) {
                xhrGetIcon(getEnv('STATIC_BASE_URL') + '/svg/app-store.svg', function (r) {
                    if (r) {
                        var sectButtonStore = createItem('div', 'sb_section sbs_store'),
                            btn = createItem('a', 'app_store_link');

                        btn.setAttribute('target', '_blank');
                        btn.setAttribute('href', getEnv('APPLE_BANNER_URL'));

                        btn.innerHTML = r;

                        inner.appendChild(sectButtonStore);
                     //   sectButtonStore.appendChild(btn);
                    }
                });
            }
            if (isAndroid()) {
                xhrGetIcon(getEnv('STATIC_BASE_URL') + '/svg/google-play.svg', function (r) {
                    if (r) {
                        var sectButtonStore = createItem('div', 'sb_section sbs_store'),
                            btn = createItem('a', 'app_store_link');

                        btn.setAttribute('target', '_blank');
                        btn.setAttribute('href', 'https://play.google.com/store/apps/details?id=com.humandesignx');

                        btn.innerHTML = r;

                        inner.appendChild(sectButtonStore);
                        // sectButtonStore.appendChild(btn);
                    }
                });
            }

        },

        openSidebar: function () {
            sidebarOpened = true;
            holder.classList.add('opened');
            bg.classList.add('vis');
        },

        addAvatar: function () {
            if (isAuthorized()) {
                xhrGet(getBaseApiUrl() + '/account/dashboard', function (r) {
                    if (r.user) {
                        var colored = r.hdData ? r.hdData.isPaid : false;
                        var avatar = renderAvatar(r.user, colored),
                            greeting = createItem('p'),
                            span = createItem('span', 'greeting_name');

                        switcher.classList.add('authd');
                        switcher.innerHTML = '';
                        switcher.appendChild(avatar);

                        greeting.innerHTML = lang('Привет, ');
                        span.innerHTML = r.user.name;
                        greeting.appendChild(span);
                        switcher.appendChild(greeting);

                        // updateCommunityLink(r.hdData ? r.hdData.community_link : null);
                    }
                })
            }
            else {
                switcher.classList.remove('authd');
                switcher.innerHTML = '<i></i>';
            }
        },

        setLightTheme: function (isLight) {
            let greeting_name=document.getElementsByClassName('greeting_name')[0]
            if(!greeting_name)
            {
                return;
            }
            if (isLight)
            {
                greeting_name.style='color: #fff';
            }
            else
            {
                greeting_name.style='';
            }
        }


    }
}

function updateCommunityLink(url) {
    var link1 = document.querySelector('.sb_section .community');
    var link2 = document.querySelector('.pm_wrapper .community');
    if (getEnv('SHOW_COMMUNITY_LINK') && url) {
        link1.setAttribute('href', url);
        link1.classList.remove('hidden');
        link2.setAttribute('href', url);
        link2.classList.remove('hidden');
    } else {
        link1.classList.add('hidden');
        if (link2) {
            link2.classList.add('hidden');
        }
    }
}

function renderAvatar(data, paid) {
    var wrapper = createItem('div', 'avatar_wrapper'),
        imgHolder = createItem('div', 'pic_holder'),
        img = createItem('img'),
        letter = createItem('span');

    wrapper.appendChild(letter);
    letter.innerHTML = data.name.split('')[0];

    if (paid) {
        wrapper.classList.add('paid');
    }
    if (data.avatar) {
        img.setAttribute('src', data.avatar);
        imgHolder.appendChild(img);
        wrapper.appendChild(imgHolder);
    }

    return wrapper;
}

export function renderFooter(wrapper, cls, paddingTop) {
    var footer = createItem('div', 'footer'),
        cpr = createItem('span', 'cpr'),
        ua = createItem('a', 'ua'),
        phone = createItem('span', 'phone'),
        oldFooter = document.querySelectorAll('.footer');

    footer.style.clear='both';
    if (oldFooter.length) {
        oldFooter.forEach(function (el) {
            el.parentNode.removeChild(el);
        });
    }


    if (cls) {
        footer.classList.add(cls);
    }
    if (paddingTop) {
        footer.style.paddingTop=paddingTop;
    }

    wrapper.appendChild(footer);

    footer.appendChild(ua);
    ua.setAttribute('target', '_blank');
    ua.setAttribute('href', '/terms');
    ua.innerHTML = lang('Условия использования');

    footer.appendChild(phone);
    const PHONE_NUMBER = window.hydrateVars?.CONTENT_CONTACTS?.phone;
    if (PHONE_NUMBER) {
        phone.innerHTML = '<a href="tel:'+'+'+PHONE_NUMBER.replace(/[\W_]/g, "")+'">'+PHONE_NUMBER +'</a>' + lang(' - горячая линия');
    }

    footer.appendChild(cpr);
    var year = new Date().getFullYear();
    cpr.innerHTML = '© 2017 - ' + year + ' Human Design X';
}
