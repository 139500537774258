import "./styles/common-styles.scss"

document.oncopy = function () {
    var bodyElement = document.body;
    var selection = getSelection();
    var href = document.location.href;
    var copyright = "<br><br>Источник: <a href='"+ href +"'>" + href + "</a><br>© Human Design X";
    var text = selection + copyright;
    var divElement = document.createElement('div');
    if(selection.toString().includes('%%%%'))
    {
        text=selection.toString().split('%%%%')[1];
    }
    divElement.style.position = 'absolute';
    divElement.style.left = '-99999px';
    divElement.innerHTML = text;
    bodyElement.appendChild(divElement);
    selection.selectAllChildren(divElement);
    setTimeout(function() { bodyElement.removeChild(divElement); }, 0);
};

let IS_FROM_FACTS_TO_SEO;
export function initIsFromFactsToSeo() {
    IS_FROM_FACTS_TO_SEO = localStorage['IS_FROM_FACTS_TO_SEO'] ? localStorage['IS_FROM_FACTS_TO_SEO'] : false;
}

export function setIsFromFactsToSeo(v) {
    IS_FROM_FACTS_TO_SEO = v;
    localStorage.setItem('IS_FROM_FACTS_TO_SEO', IS_FROM_FACTS_TO_SEO);
}

export function getIsFromFactsToSeo() {
    return IS_FROM_FACTS_TO_SEO;
}



document.addEventListener('keydown', function (e) {
    if(e.keyCode === 27)
    {
        let modal_div=document.getElementsByClassName('modal_holder');
        if(modal_div && modal_div.length>0) {
            modal_div[0].remove();
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = 'unset';
        }
    }
});
window.onpopstate = function(event) {
    let modal_div=document.getElementsByClassName('modal_holder');
    if(modal_div && modal_div.length>0) {
        modal_div[0].remove();
        document.body.style.overflow = 'unset';
        document.documentElement.style.overflow = 'unset';
    }
};
