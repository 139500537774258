import { render404 } from "../routing";
import { getBaseApiUrl, parseQS, setTemporaryCookie, xhrGet } from "./utils";
import * as Sentry from "@sentry/browser";

export function setReferralCookie(pName, {
        found = () => {},
        notFound = render404
    } = {}) {
    const cookieName = 'ref_visit';

    xhrGet(getBaseApiUrl() + '/order/ref/' + pName, function (r) {
        if (r.found) {
            setTemporaryCookie(cookieName, { type: 'agent', name: pName }, 1440 * 60000);
            found();
        } else {
            notFound();
        }
    });
}

export function setRefferalFromQS() {
    let query_data = parseQS(location.search.substring(1));
    if (query_data['utm_campaign']) {
        setReferralCookie(query_data['utm_campaign'], {
            notFound: () => {
                const msg = `utm_campaign referral=${query_data['utm_campaign']} not found`;
                console.log(msg)
                Sentry.captureMessage(msg);
            }
        });
    }
}
