import "vite/modulepreload-polyfill";

import "./styles/misc.scss";
import "./styles/layout.scss";
import "./styles/logo-white.scss";

import { initRouting, onNavigate } from "./routing.js";
import { refreshToken, xhrGetIcon } from "./shared/utils.js";
import { initCurrentLanguage } from "./shared/lang.js";
import { initCurrentTheme } from "./shared/theme.js";
import { getEnv, initLocalEnvMethods } from "./shared/env.js";
import { initNavSidebar } from "./controllers/navigation/navigation";

import * as Sentry from "@sentry/browser";

export function appEntry(pageContext) {

    initLocalEnvMethods();

    Sentry.init({
        dsn: "https://1bf2112ec957b6a42b50233285bb28fb@o4505753569918976.ingest.sentry.io/4505753571688448",
        integrations: [
            // new Sentry.BrowserTracing({
            //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            //     tracePropagationTargets: [
            //         "localhost",
            //         "https:yourserver.io/api/",
            //     ],
            // }),
            // new Sentry.Replay(),
        ],
        // Performance Monitoring
        // tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    if (pageContext.isHydration) {
        initCurrentTheme();

        // TODO: remove $ dep
        // $(document).ready(function () {
        //     setInterval(function(){
        //         $('#mobile_menu>ul').addClass('blink_on');
        //         setTimeout(function(){$('#mobile_menu>ul').removeClass('blink_on')},1500);
        //     },6000)
        // });

        initCurrentLanguage().then(function () {
            initNavSidebar();
            refreshToken(initRouting);
        });

        xhrGetIcon(
            getEnv("STATIC_BASE_URL") + "/svg/logo_white.svg",
            function (r) {
                var logo = document.querySelectorAll(".logo")[0];
                logo.innerHTML = r;
            }
        );
    } else {
        onNavigate();
    }
}
