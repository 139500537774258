let INTERVALS = [];

export function clearAllIntervals() {
    INTERVALS.forEach(function (el) {
        clearInterval(el);
    });

    INTERVALS = [];
}

export function pushInterval(v) {
    INTERVALS.push(v);
}
