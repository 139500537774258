import './styles.scss'

function camelToKebab(str) {
    return str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
}

export async function modalTemplates(key) {
    return (await import(`./templates/${camelToKebab(key)}.js`)).default;
}

import { getEnv } from "../../shared/env";
import { createItem } from "../../shared/utils";

export function modalController(opts) {
    var PARENT_NODE = document.body,
        MODAL_HOLDER = createItem('div', 'modal_holder'),
        MODAL_BG = createItem('div', 'modal_bg'),
        MODAL_CONTAINER = createItem('div', 'modal_container'),
        MODAL_COLOR_BG = createItem('img', 'modal_color_bg'),
        MODAL_INNER = createItem('div', 'modal_inner'),
        MODAL_CLOSE_BTN = createItem('div', 'modal_close_btn'),
        PARTS = {
            inner: MODAL_INNER,
            container: MODAL_CONTAINER
        };
    history.pushState(null, null, window.location.href);

    if (!opts) {
        opts = {};
    }

    if (opts.inner_cls) {
        MODAL_CONTAINER.classList.add(opts.inner_cls);
    }

    if(opts.isNewVip)
    {
        MODAL_CONTAINER.classList.add('new_vip_card');
    }

    if (opts.inner_cls !== 'hub') {
        MODAL_CLOSE_BTN.classList.add('new_modal');
        MODAL_CONTAINER.classList.add('new_modal');
    }
    else
    {
        MODAL_HOLDER.addEventListener('click', function () {
            HIDE_MODAL();
        }, false);
    }

    PARENT_NODE.appendChild(MODAL_HOLDER);
    MODAL_HOLDER.appendChild(MODAL_BG);
    MODAL_HOLDER.appendChild(MODAL_CONTAINER);
    MODAL_CONTAINER.appendChild(MODAL_INNER);
    MODAL_CONTAINER.appendChild(MODAL_CLOSE_BTN);

    MODAL_CONTAINER.appendChild(MODAL_COLOR_BG);
    MODAL_COLOR_BG.setAttribute('src', getEnv('STATIC_BASE_URL') + '/i/purpleLight.png');
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';

    const CLOSE_MODAL = function () {
        PARENT_NODE.removeChild(MODAL_HOLDER);
        document.body.style.overflow = 'unset';
        document.documentElement.style.overflow = 'unset';
        if (opts.onClose) {
            opts.onClose();
        }
    };

    const HIDE_MODAL = function () {
        MODAL_HOLDER.style.display = 'none';
        document.body.style.overflow = 'unset';
        document.documentElement.style.overflow = 'unset';
        if (opts.onHide) {
            opts.onHide();
        }
    };

    const SHOW_MODAL = function () {
        MODAL_HOLDER.style.display = 'block';
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
        if (opts.onShow) {
            opts.onShow();
        }
    };

    MODAL_CLOSE_BTN.addEventListener('click', CLOSE_MODAL, false);
    if (!opts.prompt) {
        MODAL_BG.addEventListener('click', CLOSE_MODAL, false);
    }

    setTimeout(function () {
        MODAL_BG.classList.add('vis');

        if (opts.after_render) {
            opts.after_render(PARTS);
        }
    }, 10);

    return {
        container: MODAL_INNER,
        remove: CLOSE_MODAL,
        hide: HIDE_MODAL,
        show: SHOW_MODAL
    }

}

